import { format, isToday, isTomorrow } from "date-fns";
import { isString } from "./stringUtils";
import { isNumber } from "./numberUtils";
import { requireValue } from "modules/picasso-modules/utils/validateUtils";

export const nowEpochSeconds = () => {
    return Math.round(Date.now() / 1000)
}

export const dateFromAnyValue = (value) => {
    requireValue(value)

    if (isNaN(value)) {//parse formatted string
        return dateFromApiString(value)
    } else {
        return dateFromEpochSeconds(value)
    }

} 

export const dateFromApiString = (str) => {
    requireValue(str)

    const dateParts = str.split('.');

    if (dateParts.length === 3) {
        const day = parseInt(dateParts[0], 10);
        const month = parseInt(dateParts[1], 10) - 1; // Months are zero-based in JavaScript Date
        const year = parseInt(dateParts[2], 10);

        return new Date(year, month, day);
    }

    return new Date(str)
}

export const dateFromEpochSeconds = (seconds) => {
    requireValue(seconds)
    return new Date(1000 * seconds)
}

let localeDateFormat = null;

export const getLocaleDateFormat = () => {
    if (!localeDateFormat) {
        localeDateFormat = loadLocaleDateFormat();
    }
    return localeDateFormat;
};

export const formatDateFromSeconds = (seconds) => {
    let date = new Date(1000 * seconds);
    return format(date, getLocaleDateFormat());
}

export const formatDate = (date, formatParam) => {
    requireValue(date)
    return format(date, formatParam || getLocaleDateFormat())
}

export const formatDateTimeWithLabels = (date, dateFormat, timeFormat) => {
    requireValue(date)

    let dateLabel = ''
    if (isToday(date)) {
        dateLabel = 'today ';
        dateFormat = ''
    } else if (isTomorrow(date)) {
        dateLabel = 'tomorrow ';
        dateFormat = ''
    }

    return dateLabel + format(date, dateFormat + timeFormat)
}

export const formatDateTimeFromSecondsWithSeconds = (seconds) => {
    try {
        let date = new Date(1000 * seconds);
        return format(date, getLocaleDateFormat() + ' HH:mm:ss');
    } catch (e) {
        console.error('invalid input: ' + e)
        throw e
    }

}

export const formatDateTimeLocal = (date) => {
    requireValue(date)
    return format(date, getLocaleDateFormat() + ' HH:mm');
}

export const formatDateTimeLocalWithSeconds = (date) => {
    requireValue(date)
    return format(date, getLocaleDateFormat() + ' HH:mm:ss');
}

export const formatDateTimeFromSeconds = (seconds) => {
    try {
        let date = new Date(1000 * seconds);
        return format(date, getLocaleDateFormat() + ' HH:mm');
    } catch (e) {
        console.error('invalid input: ' + e)
        throw e
    }

}

export const toTimeMedium = (date) => {
    requireValue(date)
    return date.toISOString().substr(11, 8)
}

export const toDateMedium = (date) => {
    requireValue(date)
    return date.toISOString().substr(0, 10)
}

export const toDateTimeMedium = (date) => {
    requireValue(date)
    return date.toISOString()
}

export const fromApiTimeToBrowserTime = (createdAt) => {
    if (isString(createdAt)) {
        //parse string formatted from api and convert to browser time string
        return formatDateTimeFromSeconds(Date.parse(createdAt) / 1000);
    } else {
        throw new Error('unepexted format. input='+createdAt)
    }
}

export const fromApiTimeToBrowserTimeV2 = (createdAt) => {
    if (isString(createdAt)) {
        //parse string formatted from api and convert to browser time string
        return formatDateTimeFromSeconds(Date.parse(createdAt) / 1000);
    } else if (isNumber(createdAt)) {
        return formatDateTimeFromSeconds(createdAt);
    } else {
        throw new Error('unepexted format. input='+createdAt)
    }
}

export const fromApiTimeToBrowserDateStringMedium = (createdAt) => {
    return fromApiTimeToBrowserTimeV2(createdAt).substring(0, 10)
}

export const fromApiTimeSecondsToBrowserTimeWithSince = (seconds) => {
    requireValue(seconds)

    const millis = 1000 * seconds
    const asDate = new Date(millis)

    return {
        date: formatDateTimeFromSeconds(seconds),
        // the '-5' is a small buffer to show it as 'now'
        since: (millis-5000) <= Date.now() ? timeSince(asDate) : null,
    }
}

export const fromApiTimeToBrowserTimeWithSince = (createdAt) => {
    if (isString(createdAt)) {
        const asDate = Date.parse(createdAt)
        //parse string formatted from api and convert to browser time string
        return {
            date: formatDateTimeFromSeconds(asDate / 1000),
             // the '-5' is a small buffer to show it as 'now'
            since: asDate <= Date.now() ? timeSince(asDate) : null,
        }
    } else {
        throw new Error('unepexted format. input='+createdAt)
    }
}

export const fromApiTimeToDate = (createdAt) => {
    if (isString(createdAt)) {
        return Date.parse(createdAt)
    }
    else {
        throw new Error('unepexted format. input='+createdAt)
    }
}

export const fromApiTimeToDateV2 = (val) => {
    requireValue(val)
    if (isString(val)) {
        return new Date(val)
    }
    else if (isNumber(val)) {
        return new Date(1000 * val)
    }
    else {
        throw new Error('unepexted format. input='+val)
    }
}

export const fromApiTimeToUtcSeconds = (val) => {
    requireValue(val)
    if (isString(val)) {
        return Math.floor(Date.parse(val)/1000)
    }
    else if (isNumber(val)) {
        return val
    }
    else {
        throw new Error('unepexted format. input='+val)
    }
}

export const isOlderThanSeconds = (date, seconds) => {
    requireValue(date)
    requireValue(seconds)

    return (new Date().getTime()-date.getTime()) > seconds*1000
}

export const isOlderThanDays = (date, days) => {
    requireValue(date)
    requireValue(days)

    return isOlderThanSeconds(date, days * 24 * 60 * 60)
}

export const isTimestampMillisOlderThanSeconds = (timestampMillis, seconds) => {
    requireValue(timestampMillis)
    requireValue(seconds)

    return (new Date().getTime()-timestampMillis) > seconds*1000
}

export const isTimestampMillisOlderThanHours = (timestampMillis, hours) => { 
    return isTimestampMillisOlderThanSeconds(timestampMillis, hours * 60 * 60)
}

export const getHoursSinceTimestampMillis = (timestampMillis) => {
    requireValue(timestampMillis)
    const seconds = Math.floor((Date.now()-timestampMillis) / 1000)
    const hours = Math.floor(seconds / 3600)
    return hours
}

export const timeSince = (date) => {
    requireValue(date)

    var seconds = Math.floor((new Date() - date) / 1000);

    if (seconds < 60) {
        if (seconds < 1) {
            return "0 seconds"
        }
        return Math.floor(seconds) + " seconds";
    }
  
    var interval = seconds / 31536000;
    
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }

    return Math.floor(seconds) + " seconds";
}

const formats = {
    "de": "dd.MM.yyyy",
    "de-at": "dd.MM.yyyy",
    "de-ch": "dd.MM.yyyy",
    "de-de": "dd.MM.yyyy",
    "en": "dd/MM/yyyy",
    "en-ca": "dd/MM/yyyy",
    "en-gb": "dd/MM/yyyy",
    "en-us": "M/d/yyyy",
    "es-es": "dd/MM/yyyy",
    "fr-be": "d/MM/yyyy",
    "fr-ca": "yyyy-MM-dd",
    "fr-ch": "dd.MM.yyyy",
    "fr-fr": "dd/MM/yyyy",
    "fr-lu": "dd/MM/yyyy",
    "fr-mc": "dd/MM/yyyy",
};

const loadLocaleDateFormat = () => {
  
    let defaultFormat = "dd/MM/yyyy";

    if (typeof window === "undefined") {
        return defaultFormat;
    }

    if (window.yoioContext?.locale) {
        const format = formats[window.yoioContext.locale] 
        if (format) return format;
    }

    if (typeof navigator === "undefined") {
        return defaultFormat;
    }

    if (!navigator) {
        return defaultFormat;
    }
    if (!navigator.language) {
        return defaultFormat;
    }

    return formats[navigator.language.toLowerCase()] || defaultFormat;
  }

  export const isOnDay = (testedDate, expectedDate) => {
    requireValue(testedDate)
    requireValue(expectedDate)
    if (testedDate.getDate() === expectedDate.getDate() &&
        testedDate.getMonth() === expectedDate.getMonth() &&
        testedDate.getFullYear() === expectedDate.getFullYear()) {
        return true;
    }
    return false;
}

export const isValidDate = (date) => {
    // Check if the input is an instance of Date and is not an "Invalid Date"
    return date instanceof Date && !isNaN(date);
}