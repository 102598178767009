import Head from "next/head"
import { FunctionComponent } from "react"
import { notNullNotUndefined } from "utils/objectUtils"
import { removeLeading, removeTrailing } from "utils/stringUtils"

const fonts = ['lora', 'dosis', 'mulish', 'cormorant', 'raleway', 'caveat']
// 'inter' loaded per default for now

export interface FontLoaderProps {
    fontFamilies: string[]
}

export const FontLoader: FunctionComponent<FontLoaderProps> = ({fontFamilies}) => {
    notNullNotUndefined(fontFamilies)

    const keys = fontFamilies
        .map(f=>removeTrailing(removeLeading(removeTrailing(removeLeading(f.toLowerCase(), '"'), '"'), "'"), "'"))
        .filter(f=>fonts.includes(f))

    if (keys.length === 0) {
        return null;
    }

    return <>
        {keys.map(k=>
            <Head key={"font"+k}>
                <link href={`/fonts/fonts-${k}.css`} rel="stylesheet" />
            </Head>
        )}
    </>

}