import { notify } from 'modules/yoio/errorsService';
import { isError } from 'utils/objectUtils';
import alerts from '../../modules/yoio/state/alerts';

const getFirstError = (error) => {
  if (Array.isArray(error)) {
      return error[0];
  }
  return error;
}

const getMessageIfErrorOrNull = (error, status) => {
  if (error.type === undefined) {
    return null;
  }

  if (error.type === null) {
    return null;
  }

  if (error.type === 'quota' && error.upgradeProposal) {
    if (error.message) {
      return {
        type: 'quotaWithUpgradeProposal',
        severity: 'error',
        text: error.message,
        upgradeProposal: error.upgradeProposal,
        limit: error.limit,
      };
    }
  } else if (error.type === 'quota') {
    if (error.message) {
      return {
        type: 'quota',
        severity: 'info',
        text: error.message,
        limit: error.limit,
      };
    }
  }

  if (error.type === 'access') {
    if (error.message) {
      return {
        severity: 'error',
        text: error.message,
      };
    }
  }

  if (error.type === 'simple') {
    if (error.message) {
      return {
        severity: 'error',
        text: error.message,
      };
    }
  }

  if (error.type === 'notFound') {
    if (error.message) {
      return {
        severity: 'error',
        text: error.message,
      };
    }
  }

  if (error.type === 'validation') {
    if (error.message) {
      let message = '';
      if (error.field) {
        message = error.field + ' ' + error.message;
      } else {
        message = error.message;
      }

      return {
        severity: 'error',
        text: message,
      };
    }
  }

  return null;
};

const getHandling = (error, options) => {

  options = options || {}

  // Result
  const handling = {
    alertMessage: null,
    notify: true,
    notifyMessage: null,
    forwardOnReject: true,
    apiErrorFirst: null
  }

  // Internal values
  let responseData = null
  let apiErrorFirst = null

  if (error.response) {
    // Unexpected response status

    let status = error.response.status;

    responseData = error?.response?.data
    apiErrorFirst = responseData ? getFirstError(responseData) : null
    if (apiErrorFirst) {
      // Try to get alert message from response body
      // Can be still null afterwards
      handling.alertMessage = getMessageIfErrorOrNull(apiErrorFirst, status)
      handling.notifyMessage = apiErrorFirst.message
      handling.apiErrorFirst = apiErrorFirst
    }

    

    // Handle status. If no alert message so far, get based on status.
    if (status === 403) {
      if (!handling.alertMessage) {
        handling.alertMessage = {
          severity: 'error',
          text: 'You are not authorized to view or change this resource.',
        }
      }
    }
    else if (status === 404) {
      if (!handling.alertMessage) {
        handling.alertMessage = {
            severity: 'error',
            text: 'Resource not found.',
        }
      }
    }
    else if (status === 429) {
      if (!handling.alertMessage) {
        handling.alertMessage = {
          severity: 'error',
          text: 'Too many requests.',
        }
      }
    }
    else if (status === 500) {
      if (!handling.alertMessage) {
        handling.alertMessage = {
          severity: 'error',
          text: 'Something has gone wrong. Our team has been notified.',
        }
      }
    }
    else if (status === 401) {
      if (!handling.alertMessage) {
        handling.alertMessage = {
          severity: 'error',
          text: 'Unauthorized',
        }
      }
    }

  } else if (error?.message === 'Network Error') {
    // Network error
    
    if (error.config?.method?.toLowerCase() === 'get') {
      // Let SWR handle a network error
      handling.alertMessage = null
      if (typeof window !== 'undefined') {
        // Only if this happens on browser side (let SWR notify on retry)
        handling.notify = false
      }
      handling.forwardOnReject = true
    } else {
      handling.alertMessage = {
        severity: 'error',
        text: 'Network connection error',
      }
    }

  } else {
    // All other errors

    handling.alertMessage = {
      severity: 'error',
      text: 'An unexpected error occured.',
    }

  }

  // Apply options
  if (options.supressErrorMessage === true) {
    handling.alertMessage = null
  }
  if (options.supressErrorMessageIfAccessError === true) {
    if (apiErrorFirst?.type === 'access') {
      handling.alertMessage = null
    }
  }
  if (options.supressErrors) {
    if (additionalConfig.supressErrors(responseData)) {
      handling.alertMessage = null
    }
  }

  return handling;
}

export const onResponseError = (error) => {

  if (error?.response?.data) {
    console.debug('Request Failed. Response: ', error.response.data)
  } else {
    console.debug('Request Failed. No error or no response received.')
  }
  
  const handling = getHandling(error, error?.config?.additionalConfig)

  // Alert to user
  if (handling.alertMessage) {
    alerts.setMessage(handling.alertMessage)
  }

  // Notify
  if (handling.notify) {
    try {
      const notifiableError = isError(error) ? error : new Error(handling?.apiErrorFirst?.message || error?.message || error)
      notify(notifiableError, (event)=>{
        if (handling.notifyMessage) {
          if (event.errors[0]?.errorMessage) {
            event.errors[0].errorMessage = event.errors[0].errorMessage + '. ' + handling.notifyMessage
          }
        }
      })
    } catch (error) {
      console.error('error: notify failed', error)
    }
  }

  // Attach data to error 
  if (handling.apiErrorFirst) {
    error.apiErrorFirst = handling.apiErrorFirst
  }
  error.notified = true

  if (handling.forwardOnReject) {
    return Promise.reject(error)
  } else {
    return Promise.reject()
  }

}


//error handler on ui layer (not the http response directly) 

/**
 * Use this error handler if the UI does not need to recover from the validation error
 * 
 * @param {*} e 
 */
export const onValidationErrorNothingTodo = (e) => {
  if (e.type === 'validation') {
    //swallow so that it does not pop up as unhandled
  } else {
    throw e
  }
}